
.step-one {
    height: 100%;
    .step-content {
        min-height: 500px;
        .step-item {
            padding: 30px 30px 30px 0;
            margin-bottom: 20px;
            background-color: #fff;
            .placement-item {
                display: flex;
                align-items: center;
                margin-bottom: 35px;
                .text {
                    position: relative;
                    width: 132px;
                    display: inline-block;
                    text-align: right;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #333333;
                    margin-right: 50px;
                    &>span {
                        position: absolute;
                        right: -30px;
                        top: -2px;
                    }
                    .prompt-icon {
                        color: #D7D7D7;
                        cursor: pointer;
                        font-size: 14px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .placement-radio-group {
                    ::v-deep {
                        .el-radio {
                            width: 136px;
                        }
                    }
                }
                .name-input {
                    width: 540px;
                }
                .toutiao-jingdong {
                    &>span:nth-child(1) {
                        position: absolute;
                        right: -364px;
                        top: -2px;
                    }
                    &>span:nth-child(2) {
                        position: absolute;
                        right: -474px;
                        top: -2px;
                    }
                    &>span:nth-child(3) {
                        position: absolute;
                        right: -584px;
                        top: -2px;
                    }
                }
                .toutiao-toutiao {
                    &>span {
                        position: absolute;
                        right: -460px;
                    }
                }
                .baidu-jingdong {
                    &>span:nth-child(1) {
                        position: absolute;
                        right: -364px;
                        top: -2px;
                    }
                    &>span:nth-child(2) {
                        position: absolute;
                        right: -474px;
                        top: -2px;
                    }
                }
                .kuaishou-jingdong {
                    &>span:nth-child(1) {
                        position: absolute;
                        right: -305px;
                        top: -2px;
                        z-index: 999;
                    }
                    &>span:nth-child(2) {
                        position: absolute;
                        right: -460px;
                        top: -2px;
                    }
                }
                .baidu-biaoqian {
                    &>span {
                        position: absolute;
                        right: -480px;
                    }
                }
                .kuaishou-kuaishou {
                    &>span {
                        position: absolute;
                        right: -300px;
                    }
                }
            }
            .placement-item.tengxun:nth-child(3) {
                .text {
                    position: relative;
                    &>span {
                        position: absolute;
                        right: -460px;
                    }
                }
            }
        }
    }
}
::v-deep .el-popover {
    font-size: 12px;
}
.daily-limit-tip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 114px;
    background-color: #F8F8F8;
    color: #333;
    font-size: 12px;
    padding: 0 20px;
    margin-left: 30px;
    .daily-limit-tip-item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.quota-input {
    display: flex;
    .quota-yuan {
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 16px;
        .quota-yuan-tip {
            color: #666666;
            font-size: 14px;
            margin-left: 10px;
        }
    }
}
.promotionPlanType {
    ::v-deep .el-radio:first-child {
        .el-radio__label {
            position: relative;
        }
    }
}
.placement-text {
    position: absolute;
    left: 4px;
    top: -24px;
    width: 32px;
    height: 22px;
    background: #FD4446;
    border-radius: 2px;
    color: #fff;
    line-height: 22px;
    font-size: 12px;
    text-align: center;
}
.corner {
    position: absolute;
    top: -2px;
    left: 17px;
    width: 0; /*  宽高设置为0，很重要，否则达不到效果 */
    height: 0;
    border: 4px solid #FD4446;
    border-bottom-color: transparent; /* 设置透明背景色 */
    border-left-color: transparent;
    border-right-color: transparent;
}
.time_table  ::v-deep {
    th:first-child > .cell {
        padding-left: 10px;
        border-left: none;
        border-top: none;
    }
    td:first-child {
        border-left: none;
    }
    .el-table__body-wrapper .el-table__body td {
        &:first-child > .cell {
            padding-left: 35px !important;
        }
        padding: 0;
        height: 30px;
        .cell {
            padding: 0;
        }
    }
    thead.is-group {
        tr {
            th {
                &:last-child {
                    border-left: none;
                    border-right: none;
                }
            }
        }
        th {
            border-top: none;
        }
        th:last-child {
            border-left: none;
            border-right: none;
        }
    }
    tbody tr:hover>td {
        .table-cell {
            color: #F5F7FA;
        }
        .is-click {
            color: #00A0E9;
        }
    }
}
.table-cell {
    height: 30px;
    cursor: pointer;
    color: #fff;
}
.is-click {
    background-color: #00A0E9;
    height: 30px;
    color: #00A0E9;
}
.select-placement {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #F8F8F8;
    font-size: 16px;
    color: #333333;
    margin-top: 20px;
    .text {
        display: inline-block;
        text-align: center;
        width: 150px;
        background: #EEEEEE;
        margin-right: 16px;
    }
}
.step-title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    padding-left: 30px;
}
.area-list {
    width: 600px;
    margin-left: 182px;
    .el-checkbox-group {
        .goods-checkbox {
            margin: 0 50px 50px 0;
            width: 100px;
        }
    }
    .all-checkbox {
        position: relative;
        top: -22px;
        ::v-deep .el-checkbox__label {
            font-size: 16px;
        }
    }
}
.tip {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-left: 180px;
}
.upload-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 242px;
    height: 431px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    .item-txt-tip {
        width: 100%;
        height: 50px;
        color: #666666;
        background: #F8F8F8;
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 0 0 4px 4px;
    }
    .cover {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .cover-uploader {
        width: 100%;
        flex: 1;
        height: 1%;

        ::v-deep.el-upload {
            width: 100%;
            height: 100%;

            .inner-box {
                width: 140px;
                height: 50px;
                background: #FD4446;
                border-radius: 4px;
                margin: 166px auto;

                .inner-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 50px;

                    .inner-text {
                        font-size: 16px;
                        color: #fff;
                    }
                }
            }

            .cover {
                position: relative;
                top: -382px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0;

                img {
                    width: 100%;
                    height: 100%;
                }

                .cover-bottom {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    background: #000000;
                    opacity: 0.5;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 36px;
                }
            }
        }
    }
}
.placement-table {
    .text {
        flex: 1;
        width: 1%;
        text-align: left;
    }
    .one-click-creativity {
        width: 74px;
        height: 24px;
        background-color: #2D98F4;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-left: 20px;
    }
    ::v-deep {
        td:first-child > .cell {
            padding-left: 0;
            padding-right: 0;
            .el-radio {
                .el-radio__label {
                    display: none;
                }
            }
        }
        .ad-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 150px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
